<template>
  <div
    class="main_column"
    :class="{
      main_column_secondary: isSecondary,
    }"
    :style="{
      backgroundColor: headerColor,
    }"
  >
    <header class="header">
      <router-link
        :to="{ name: 'home' }"
        class="logo"
        v-if="!isMobile || !isAuth"
      >
        <AppIcon
          icon="logo"
          :size="30"
          :color="isSecondary ? '#fff' : 'inherit'"
        />
      </router-link>

      <AvailableLimit
        v-if="isAuth && !hasFullPremium && !hasIndividual"
        class="header--limits"
      />

      <!-- <div class="countdown" v-if="!isMobileOrTablet">
        <span>
          {{ isAuth ? $t('app.nextBannersIn') : $t('app.bannerInOneMinute') }}
        </span>
        <div class="countdown_timer" v-if="isAuth">
          <span v-if="countdown.days">
            {{ countdown.days }} {{ $tc('app.days', countdown.days) }}
          </span>
          <span v-if="countdown.hours">
            {{ countdown.hours }} {{ $tc('app.hours', countdown.hours) }}
          </span>
          <span v-if="countdown.minutes">
            {{ countdown.minutes }}
            {{ $tc('app.minutes', countdown.minutes) }}
          </span>
          <span v-if="countdown.seconds >= 0 && !countdown.days">
            {{ countdown.seconds }}
            {{ $tc('app.seconds', countdown.seconds) }}
          </span>
        </div>
      </div> -->

      <ul class="header_nav">
        <div class="header_nav_left">
          <!-- <li v-if="!isAdmin && !isMobile" class="header_nav_item">
            <AppButton color="white">
              <img
                src="../../assets/images/emoji-videocamera.png"
                width="20"
                class="icon"
              />
              <span v-if="!isMobile" class="header_nav_btnText">{{
                $t('app.howItWorks')
              }}</span>
            </AppButton>
          </li> -->

          <li v-if="isAuth && isAdmin" class="header_nav_item">
            <AppButton color="black" href="https://tilda.cc" target="_blank">
              <img
                src="../../assets/images/tilda.png"
                width="24"
                class="icon"
              />
              <span v-if="!isMobile" class="header_nav_btnText">{{
                $t('app.gotToTilda')
              }}</span>
            </AppButton>
          </li>

          <li
            v-if="!isAdmin && isAuth && !hasPremium"
            class="header_nav_item"
            @click="onPremiumClick"
          >
            <AppButton color="golden">
              <img
                src="../../assets/images/emoji-crown.png"
                width="20"
                class="icon"
              />
              <span v-if="!isMobile" class="header_nav_btnText">{{
                $t('app.getPremium')
              }}</span>
            </AppButton>
          </li>

          <li v-if="isAuth && isAdmin" class="header_nav_item">
            <AppButton color="golden" :to="{ name: 'usersList' }">
              <img
                src="../../assets/images/emoji-man.png"
                width="20"
                class="icon"
              />
              <span v-if="!isMobile" class="header_nav_btnText">{{
                $t('app.allUsers')
              }}</span>
            </AppButton>
          </li>

          <!-- <li v-if="!isAuth" class="header_nav_item">
            <AppButton color="white">
              <img
                src="../../assets/images/emoji-highRise.png"
                width="20"
                class="icon"
              />
              <span v-if="!isMobile" class="header_nav_btnText">{{
                $t('app.aboutUs')
              }}</span>
            </AppButton>
          </li> -->

          <li
            v-if="isAuth && !isHomePage"
            class="header_nav_item"
            :style="{ width: 'auto', order: isMobile ? -1 : 'unset' }"
          >
            <AppButton color="primary" :to="{ name: 'banners' }">
              <img
                src="../../assets/images/emoji-picture.png"
                width="18"
                class="icon"
                :style="{
                  marginRight: '0.5em',
                }"
              />
              <span>{{ $t('app.create') }}</span>
            </AppButton>
          </li>
        </div>

        <div class="header_nav_right">
          <!-- <li v-if="!isAdmin && isMobile" class="header_nav_item">
            <AppButton color="white">
              <img
                src="../../assets/images/emoji-videocamera.png"
                width="20"
                class="icon"
              />
              <span v-if="!isMobile" class="header_nav_btnText">{{
                $t('app.howItWorks')
              }}</span>
            </AppButton>
          </li> -->

          <!-- <li class="header_nav_item">
            <LangDropdown :width="isMobile ? '46px' : undefined" />
          </li> -->

          <li v-if="isAuth" class="header_nav_item">
            <UserDropdown
              :width="isMobile ? '46px' : undefined"
              :isSecondary="isSecondary"
            />
          </li>
          <li v-else>
            <AppButton
              outlined
              :color="isSecondary ? 'white' : 'black'"
              @click="showModalSignin"
            >
              {{ $t('app.login') }}
            </AppButton>
          </li>
        </div>
      </ul>
    </header>
  </div>
</template>

<script>
import UserDropdown from '@/components/UserDropdown.vue';
import { useAppStore } from '@/stores/useAppStore';
import { useUserStore } from '@/stores/useUserStore';
import isMobileUtil from 'is-mobile';
// import LangDropdown from './LangDropdown/LangDropdown.vue';
import AvailableLimit from './AvailableLimit/AvailableLimit.vue';

export default {
  name: 'AppHeader',
  setup() {
    return {
      appStore: useAppStore(),
      userStore: useUserStore(),
      isMobileOrTablet: isMobileUtil({ tablet: true }),
      isMobile: isMobileUtil(),
    };
  },
  components: {
    AvailableLimit,
    UserDropdown,
    // LangDropdown,
  },
  methods: {
    showModalSignin() {
      this.appStore.showModalAuth('signin');
    },
    onPremiumClick() {
      this.$router.push({ name: 'pricing' });
    },
  },
  computed: {
    isSecondary() {
      return this.isMobile && this.$route.path === '/';
    },
    isAuth() {
      return this.userStore.isAuthenticated;
    },
    isHomePage() {
      return this.$route.path === '/';
    },
    isAdmin() {
      return this.userStore.isAdmin;
    },
    hasPremium() {
      return this.userStore.hasPremium;
    },
    hasFullPremium() {
      return this.userStore.hasFullPremium;
    },
    hasIndividual() {
      return this.userStore.hasIndividual;
    },
    countdown() {
      return this.appStore.countdown;
    },
    headerColor() {
      if (!this.isMobile) {
        return 'inherit';
      }

      if (this.$route.path === '/banners') {
        return '#FFF';
      }

      if (this.$route.path === '/') {
        return '#2467ec';
      }

      return 'inherit';
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  height: 80px;

  &--limits {
    margin-right: 6px;

    @media screen and (min-width: 781px) {
      margin-left: 31px;
    }
  }
}
.logo {
  color: inherit;
}

.countdown {
  margin-right: auto;
  margin-left: 35px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1em;
  align-items: center;
  color: #989898;
}
.countdown_timer {
  color: var(--color-accent);
  display: inline;

  span {
    &::before {
      content: ' ';
    }
  }
}

.header_nav {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: 781px) {
    margin-left: auto;
    width: unset;

    &_left {
      margin-right: 80px;
    }
  }

  &_right,
  &_left {
    display: flex;
    column-gap: 9px;

    @media screen and (max-width: 780px) {
      column-gap: 6px;
    }
  }

  @media screen and (max-width: 780px) {
    &_item {
      width: 46px;

      > * {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 780px) {
    &_btnText {
      display: none;
    }

    .icon {
      margin-right: 0;
    }
  }
}
</style>
