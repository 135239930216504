import { defineStore } from 'pinia';
import api from '@/../lib/api';

export const useCategoriesStore = defineStore('categories', {
  state: () => {
    return {
      categories: {
        data: [],
        status: '',
        error: '',
      },
      commonCategories: {
        data: [],
        status: '',
        error: '',
      },
      category: {
        data: null,
        status: '',
        error: '',
      },
      selectedCategory: null,
      searchingCategory: null,
    };
  },
  getters: {
    categoriesList() {
      return this.categories.data;
    },
    commonCategoriesList() {
      return this.commonCategories.data;
    },
  },
  actions: {
    async getCategories() {
      this.categories.status = 'loading';
      try {
        const { data } = await api.categories.get();
        this.categories.data = data.categories;
        this.categories.status = 'success';
        this.categories.error = null;
        return Promise.resolve();
      } catch (e) {
        this.categories.status = 'error';
        this.categories.error = e;
        return Promise.reject(e);
      }
    },
    async getCommonCategories() {
      this.commonCategories.status = 'loading';
      try {
        const { data } = await api.categories.getCommonCategories();

        this.commonCategories.data = data.categories;
        this.commonCategories.status = 'success';
        this.commonCategories.error = null;
        return Promise.resolve();
      } catch (e) {
        this.commonCategories.status = 'error';
        this.commonCategories.error = e;
        return Promise.reject(e);
      }
    },
    async createCategory(payload) {
      try {
        const { data } = await api.categories.create(payload);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async updateCategory(payload) {
      try {
        const { data } = await api.categories.update(payload);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async deleteCategory(id) {
      try {
        const { data } = await api.categories.delete(id);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async restoreCategory(id) {
      try {
        const { data } = await api.categories.restore(id);
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    selectCategory(category) {
      this.selectedCategory = category;
    },
  },
});
