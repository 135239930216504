import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/useUserStore';
import { useAppStore } from '@/stores/useAppStore';
import { useColorsStore } from '@/stores/useColorsStore';

const routes = [
  // {
  //   path: '/login',
  //   name: 'home',
  //   component: () =>
  //     import(/* webpackChunkName: "userEditor" */ '../views/Old_HomeView.vue'),
  // },
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/HomeView/HomeView.vue'),
  },
  {
    path: '/banners',
    name: 'banners',
    component: () =>
      import(
        /* webpackChunkName: "bannerGenerator" */ '../views/BannerGenerator/BannerGenerator.vue'
      ),
  },
  {
    path: '/user/edit/:id',
    name: 'userEditBanner',
    component: () =>
      import(
        /* webpackChunkName: "userEditor" */ '../views/UserEditor/UserEditor.vue'
      ),
    meta: {
      authOnly: true,
      withLimit: true,
    },
  },
  {
    path: '/banner/:id',
    name: 'banner',
    component: () =>
      import(/* webpackChunkName: "banner" */ '../views/BannerView.vue'),
    props: true,
  },
  {
    path: '/admin/',
    name: 'adminBanners',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/BannersList.vue'),
    meta: {
      authOnly: true,
      adminOnly: true,
    },
  },
  {
    path: '/admin/banner/new',
    name: 'addBanner',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/BannerEditor.vue'),
    meta: {
      authOnly: true,
      adminOnly: true,
    },
  },
  {
    path: '/admin/banner/:id',
    name: 'editBanner',
    component: () =>
      import(/* webpackChunkName: "admin" */ '../views/admin/BannerEditor.vue'),
    props: true,
    meta: {
      authOnly: true,
      adminOnly: true,
    },
  },
  {
    path: '/admin/users',
    name: 'usersList',
    component: () =>
      import(
        /* webpackChunkName: "admin" */ '../../lib/views/admin/UsersList.vue'
      ),
    props: true,
    meta: {
      authOnly: true,
      adminOnly: true,
    },
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () =>
      import(
        /* webpackChunkName: "pricing" */ '../views/PricingView/PricingView.vue'
      ),
  },
  {
    path: '/payment-success',
    name: 'paymentSuccess',
    component: () =>
      import(
        /* webpackChunkName: "paymentSuccess" */ '../views/payments/PaymentSuccessView/PaymentSuccessView.vue'
      ),
  },
  {
    path: '/payment-error',
    name: 'paymentError',
    component: () =>
      import(
        /* webpackChunkName: "paymentError" */ '../views/payments/PaymentErrorView/PaymentErrorView.vue'
      ),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () =>
      import(
        /* webpackChunkName: "settings" */ '../views/SettingsView/SettingsView.vue'
      ),
  },
  {
    path: '/auth/google/callback',
    name: 'googleAuth',
    component: () =>
      import(
        /* webpackChunkName: "googleAuth" */ '../views/GoogleAuth/GoogleAuth.vue'
      ),
  },
  // {
  //   path: '/ai-model',
  //   name: 'aiModel',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "aiModel" */ '../views/AiModelView/AiModelView.vue'
  //     ),
  //   meta: {
  //     authOnly: true,
  //     premiumOnly: true,
  //   },
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

const checkIsAllLoaded = () => {
  const colorsStore = useColorsStore();
  const userStore = useUserStore();

  return (
    (userStore.isAuthenticated
      ? userStore.userStatus.type === 'success'
      : true) && colorsStore.colors.status === 'success'
  );
};

router.beforeEach(async (to, from) => {
  const appStore = useAppStore();
  const userStore = useUserStore();

  if (!checkIsAllLoaded()) {
    await new Promise((res) => {
      appStore.toggleAppLoader(true);

      const interval = setInterval(() => {
        if (checkIsAllLoaded()) {
          clearInterval(interval);
          appStore.toggleAppLoader(false);

          res();
        }
      }, 100);
    });
  }

  if (
    to.matched.some((record) => record.meta.authOnly) &&
    !userStore.isAuthenticated
  ) {
    return {
      name: from?.name || 'home',
      params: {
        nextRoute: to.name,
        needsAuth: true,
      },
    };
  }

  if (
    (to.matched.some((record) => record.meta.adminOnly) &&
      !userStore.isAdmin) ||
    (to.matched.some((record) => record.meta.premiumOnly) &&
      !userStore.hasPremium)
  ) {
    return {
      name: 'home',
    };
  }

  if (
    to.matched.some((record) => record.meta.withLimit) &&
    userStore.user.limit <= 0
  ) {
    return {
      name: 'banners',
      params: { showLimitsModal: true },
    };
  }
});

export default router;
